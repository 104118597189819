body{
  margin: 0;
  padding: 0;

}

.EurestoBodyConnection{
  background: #fff;
  color: #fff;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 10px;
  position:fixed;
  width: auto;
  height: 100%;
  background-image: url(../../../../Style/font/imagecover.png);
  background-size: cover;
  //-webkit-filter: blur(5px);
  z-index: 0;
}

.header div{
  float: left;
  color: #fff;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 35px;
  font-weight: 200;
}

.header div span{
  color: #5379fa !important;
}

.login{
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  z-index: 2;
}

.login input[type=email]{
  width: 250px;
  height: 25px;
  background: transparent;
  border: 1px solid rgba(255,255,255,0.6);
  border-radius: 2px;
  color: #fff;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  font-weight: 400;
  padding: 4px;
}

.login input[type=password]{
  width: 250px;
  height: 25px;
  background: transparent;
  border: 1px solid rgba(255,255,255,0.6);
  border-radius: 2px;
  color: #fff;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  font-weight: 400;
  padding: 4px;
  margin-top: 10px;
}

.login button{
  width: 260px;
  height: 25px;
  background: #fff;
  border: 1px solid #fff;
  cursor: pointer;
  border-radius: 2px;
  color: #a18d6c;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  font-weight: 400;
  padding: 6px;
  margin-top: 10px;
}

.login button:hover{
  opacity: 0.8;
}

.login button:active{
  opacity: 0.6;
}

.login input[type=email]:focus{
  outline: none;
  border: 1px solid rgba(255,255,255,0.9);
}

.login input[type=password]:focus{
  outline: none;
  border: 1px solid rgba(255,255,255,0.9);
}

.login button:focus{
  outline: none;
}

::-webkit-input-placeholder {
  color: rgba(0,0,0,0.8) !important;
  font-size: 12px !important;
  height: 100% !important;
  vertical-align: middle !important;
}
:-moz-placeholder {
  color: rgba(0,0,0,0.8) !important;
  font-size: 12px !important;
  height: 100% !important;
  vertical-align: middle !important;
}
::placeholder {
  color: rgba(0,0,0,0.8) !important;
  font-size: 12px !important;
  height: 100% !important;
  vertical-align: middle !important;
}
:-ms-input-placeholder {
  color: rgba(0,0,0,0.8) !important;
  font-size: 12px !important;
  height: 100% !important;
  vertical-align: middle !important;
}
::-moz-placeholder {
  color: rgba(0,0,0,0.8) !important;
  font-size: 12px !important;
  height: 100% !important;
  vertical-align: middle !important;
}

