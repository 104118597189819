@import "~font-awesome/css/font-awesome.min.css";
/* STARTER LOGIN */

img, svg {
  vertical-align: middle;
}

.text-success {
  color: #28a745;
}

fieldset {
  border: none;
  margin: 0;
  padding: 0;
}

*|*:any-link:not(svg), *|*:any-link:not(a) {
  text-decoration: none;
}

.hidden {
  display: none;
}

.input-center input {
  text-align: center !important;
}

#LogoWaitFinishDiv {
  background-image: url(font/baseimg.svg);
  background-size: cover;
  text-align: center;
  height: 100%;
  width: 100%;
}

.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.4);
  transition: opacity 500ms;
  visibility: visible;
  opacity: 1;
}

.popup {
  margin: 70px auto;
  padding: 20px;
  background: #fff;
  border-radius: 5px;
  width: 30%;
  position: relative;
  transition: all 5s ease-in-out;
}

.popup h2 {
  margin-top: 0;
  color: #333;
  font-family: Arial, Helvetica, sans-serif;
}

.popup .close {
  position: absolute;
  top: 20px;
  right: 30px;
  transition: all 200ms;
  font-size: 30px;
  font-weight: bold;
  text-decoration: none;
  color: #333;
}

.popup .close:hover {
  color: #06D85F;
}

.popup .content {
  max-height: 30%;
  overflow: auto;
}

@media screen and (max-width: 700px) {
  .box {
    width: 70%;
  }
  .popup {
    width: 70%;
  }
}

.etape {
  background-color: #80D0D0;
}

// GESTION DE THEMES
$themes: (
        default: #2a6191,
        orange-black: #FB9D00,
        white: #FFF,
        blue-black: #2E9AFE,
        white-orange: #ff7f04
);
@mixin theme($name, $color) {
  .default {
    @import 'Style/colors/_default';
  }
  .orange-black {
    @import 'Style/colors/_orangeblack';
  }
  .white {
    @import 'Style/colors/_white';
  }
  .blue-black {
    @import 'Style/colors/_blueblack';
  }
  .white-orange {
    @import 'Style/colors/_whiteorange';
  }
  .ultraviolet-moutarde {
    @import 'Style/colors/_ultravioletmoutarde';
  }
  .flat {
    @import 'Style/colors/flat';
  }

  [data-theme='#{$name}'] {
    background: $color;

    &:hover {
      background: darken($color, 10%);
    }

    &[disabled] {
      background: desaturate($color, 40%);
    }
  }
}

// Including themes
// By looping through the $themes map
@each $key, $value in $themes {
  @include theme($key, $value);
}

// Some best-practice CSS that's useful for most apps
// Just remove them if they're not what you want
html {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  background: #DFDFDF;
  overflow: hidden;
  font-size: .9rem;
  font-family: Arial,
  Helvetica,
  sans-serif !important;
  line-height: 1 !important;
  background-color: #F7F7F7;
}

.legend-crit {
  text-align: left;
  font-size: .95rem;
  color: #5D5D5D;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

#page-wrap {
  overflow-y: auto;
  overflow-x: hidden;
  //background-image: url(./font/background/1.png);
  background-size: cover;
  background: #F3F3F3;
}

.fa-6 {
  font-size: 20em;
}

.fa-5 {
  font-size: 12em;
}

br {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
}

.chipData {
  float: left;
}

.tabEts {
  float: left;
}

.contentTabs {
  height: 350px;
  background-color: red;
}

.Smoothie {
  -moz-column-count: 4;
  -moz-column-gap: 20px;
  -webkit-column-count: 4;
  -webkit-column-gap: 20px;
  column-count: 4;
  column-gap: 20px;
}

#LogoWaitFinish {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
}

#LogoWaitFinishDiv {
  width: auto;
  display: inline-block;
  height: 100%;
}



.Select-control {
  color: black !important;
  //border: none !important;
  //border-radius: 0 !important;
  //background-color: transparent !important;
  //height: 27px !important;
}
.Select-input {
  //height: auto !important;
}

.Select-control {
  border: 1px solid black !important;
  background: white !important;
}

.Select-value-label {
  vertical-align: middle !important;
}

.Select--single > .Select-control .Select-value, .Select-placeholder {
  top: auto !important;
  bottom: auto !important;
  //line-height: 100% !important;
}

.Select-input > input, .Select-input, .Select-control, .Select--single > .Select-control .Select-value, .Select-placeholder {
  height: 24px !important;
  //padding: 1px !important;
  padding: 5px !important;
  line-height: 100% !important;
  font: inherit;
  margin-left: 5px !important;
  text-align: left;
}

.Select-arrow, .Select-arrow-zone {
  //display: none !important;
}

.DraftEditor-editorContainer, .DraftEditor-root, .public-DraftEditor-content {
  height: auto !important;
}

.rdw-editor-main {
  line-height: 1 !important;
  border: .5px solid lightgrey;
  border-radius: 5px;
  background: white !important;

}
h2, h3 {
  color: #000;
}

h2 a {
  text-decoration: none;
  color: #000;
}
div.example {
  margin-left: 20px;
}

div.intro {
  margin-left: 20px;
  color: #000;
}

#host {
  width: 800px;
  margin-left: auto;
  margin-right: auto;
}

/* Filepicker CSS */
.dz-preview, .dropzone .dz-preview .dz-image {
  margin: 0 !important;
  border-radius: 0 !important;
  height: 60px !important;
  width: 60px !important;
}

.dz-details {
  padding: 0 !important;
}

.dz-image > img {
  object-fit: cover !important;
}

.dropzone:hover {
  border: 1px dashed rgba(0, 0, 0, .3) !important;
}

.dropzone {
  border: 1px solid rgba(0, 0, 0, .3) !important;
}

.filepicker {
  font-family: Arial, Helvetica, sans-serif;
}

div.filepicker {
  text-align: center;
  padding: 0 !important;
  //background-color: #E1E1E1;
  //border-radius: 5px;
  min-height: 60px;
  //border: 2px dashed #C7C7C7;
}

/* Icon */
.filepicker-file-icon {
  position: relative;

  display: inline-block;

  margin: 1.5em 0 2.5em 0;
  padding-left: 45px;

  color: black;
}

.filepicker-file-icon::before {
  position: absolute;
  top: -7px;
  left: 0;

  width: 29px;
  height: 34px;

  content: '';

  //border: solid 2px #7F7F7F;
  //border-radius: 2px;
}

.filepicker-file-icon::after {
  font-size: 11px;
  line-height: 1.3;

  position: absolute;
  top: 9px;
  left: -4px;

  padding: 0;

  content: 'file';
  content: attr(data-filetype);
  text-align: right;
  letter-spacing: 1px;
  text-transform: uppercase;

  color: #fff;
  background-color: #000;
}

.filepicker-file-icon .fileCorner {
  position: absolute;
  top: -7px;
  left: 22px;

  width: 0;
  height: 0;

  //border-width: 11px 0 0 11px;
  //border-style: solid;
  //border-color: white transparent transparent #920035;

}

.filepicker.dropzone .dz-preview.dz-image-preview {
  background: none;
}

.filepicker.dropzone .dz-preview.dz-image-preview .dz-image {
  //border-radius: 5px;
}

/* React Filepicker Example CSS */

.fa-crossed:after {
  position: relative;
  content: "/";
  color: red;
  font-weight: 700;
  font-size: 1.2em;
  left: -10px;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.cursor-help {
  cursor: help;
}

.cursor-wait {
  cursor: wait;
}

.cursor-crosshair {
  cursor: crosshair;
}

.cursor-zoom-in {
  cursor: zoom-in;
}

.cursor-grab {
  cursor: grab;
}

#body-page {
  transform: none !important;
}

.text-center {
  background: #efefef;
  height: 100%;
  max-height: 100%;
}

#a-page {
  height: 100vh;
  max-height: 100vh;
  //background-size: cover;
  //transform: none !important;
  //background: white !important;
}

.body-euresto {
  //background-image: linear-gradient(180deg, #F9F9F9 50%, transparent 100%) !important;
  background: #FAFAFA;
  //background-image: url(../../Style/font/background/2.jpg);
  //background-size: cover;
  //transform: none !important;
}

[class*="disabled"] {
  color: #555555;
}

#MenuContainer {
  background-color: white;
  position: fixed;
  width: calc(100% - 200px);
  z-index: 99;
  margin-left: 200px;
}

#appMenu {
  //padding-top: -5px;
}

.MenuG {
  height: 40px;
}

#header {
  height: 100px;
  //border-bottom: 0.5px solid black;
}

.euresto-logo {
  //height: 60px;
  //width: 60px;
  //left: 5px;
  //position: relative;
  //float: left;

}

.dashboard-header {
  font-size: 28px !important;
}

#DashboardRightTitle {
  margin-top: 10px;
  display: inline-block;
  line-height: normal;
  //text-align: center;
  margin-bottom: 0px !important;
}

#DashboardRightSecondTitle {
  margin-top: 0 !important;
  min-width: 0px;
}

.DashPose {
  margin-top: 5px !important;
  font-size: 18px !important;
}

#appTitleHeader {
  width: auto;
  display: inline-block;
  height: 100%;
}

#appTitleHeader h2 {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
}

#appAccountEmpty {
  float: right;
  width: auto;
  display: inline-block;
  align-items: center;
  height: 100%;
}

#appAccount {
  position: fixed;
  right: 5px;
  z-index: 100;
  float: right;
  display: inline-block;
  align-items: center;
}

.btn:focus, .btn.focus {
  box-shadow: none;
}

@media screen and (min-width: 775px) and (max-width: 1089px) {
  #bm-burger-button button {
    right: 10px;
  }
}

@media screen and (max-width: 600px) {
  #DashboardRightTitle {
    display: none;
  }
  #DashboardRightSecondTitle {
    display: none;
  }
}

.Resizer {
  background: #000;
  opacity: .2;
  z-index: 1;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -moz-background-clip: padding;
  -webkit-background-clip: padding;
  background-clip: padding-box;
}

.Resizer:hover {
  -webkit-transition: all 2s ease;
  transition: all 2s ease;
}

.Resizer.horizontal {
  height: 11px;
  margin: -5px 0;
  border-top: 5px solid rgba(255, 255, 255, 0);
  border-bottom: 5px solid rgba(255, 255, 255, 0);
  cursor: row-resize;
  width: 100%;
}

.Resizer.horizontal:hover {
  border-top: 5px solid rgba(0, 0, 0, 0.5);
  border-bottom: 5px solid rgba(0, 0, 0, 0.5);
}

.Resizer.vertical {
  width: 11px;
  margin: 0 -5px;
  border-left: 5px solid rgba(255, 255, 255, 0);
  border-right: 5px solid rgba(255, 255, 255, 0);
  cursor: col-resize;
}

.Resizer.vertical:hover {
  border-left: 5px solid rgba(0, 0, 0, 0.5);
  border-right: 5px solid rgba(0, 0, 0, 0.5);
}
.Resizer.disabled {
  cursor: not-allowed;
}
.Resizer.disabled:hover {
  border-color: transparent;
}


.rdw-editor-toolbar {
  padding: 0 5px !important;
  margin-bottom: 0 !important;
}

.rdw-dropdown-wrapper {
  height: 20px !important;
  margin: 0 !important;
}



a:link, a:visited {
  color: inherit;
  text-decoration: inherit;
}


//DBM
.DBM-validation_besoins_reels {
  background-color: #00FF00 !important;
}

.DBM-validation_besoins_reels--text {
  color: #00FF00 !important;
}

.DBM-saisie_besoins_reels {
  background-color: #99FF99 !important;
}

.DBM-saisie_besoins_reels--text {
  color: #99FF99 !important;
}

.DBM-controle {
  background-color: #FFFF00 !important;
}

.DBM-controle--text {
  color: #FFFF00 !important;
}

.DBM-confirmation_econome {
  background-color: #FF9999 !important;
}

.DBM-confirmation_econome--text {
  color: #FF9999 !important;
}

.DBM-enregistrement_besoins {
  background-color: #00FFFF !important;
}

.DBM-enregistrement_besoins--text {
  color: #00FFFF !important;
}


.EurestoError--text {
  color: #F05D5E !important
}
.EurestoError {
  background-color: #F05D5E !important
}
.bmCrossButton{
  color: #F05D5E
}

.EurestoDisabled--text {
  color: #efefef !important
}

.EurestoDisabled {
  background-color: #efefef !important
}

.ReactTable .rt-tbody {
  display: block !important;
  overflow-x: hidden;
}

//Jodit
.jodit_container {
  min-width: 100% !important;
  min-height: 100% !important;
  max-height: 100% !important;
  height: 100% !important;
  font-family: inherit !important;
}

.jodit_workplace {
  height: calc(100% - 51px) !important;
  min-height: calc(100% - 51px) !important;
  max-height: calc(100% - 51px) !important;
  position: relative !important;
  overflow: hidden !important;
}

.jodit_wysiwyg {
  height: 100% !important;
  max-height: 100% !important;
  min-height: 100% !important;
  overflow: auto !important;
}

.jodit_wysiwyg p {
  margin: 0 !important;
}

.ei-right {
  transform: rotate(0deg);
}
.ei-down {
  transform-origin: center 4px;
  transform: rotate(90deg);
}
.ei-left {
  transform: rotate(180deg);
}
.ei-up {
  transform: rotate(270deg);
}

.loading-ellipsis:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  -webkit-animation: ellipsis steps(4,end) 900ms infinite;
  animation: ellipsis steps(4,end) 900ms infinite;
  content: "\2026"; /* ascii code for the ellipsis character */
  width: 0px;
}

@keyframes ellipsis {
  to {
    width: 20px;
  }
}

@-webkit-keyframes ellipsis {
  to {
    width: 20px;
  }
}

.blur {-webkit-filter: blur(4px);
  -moz-filter: blur(4px);
  -ms-filter: blur(4px);
  -o-filter: blur(4px);
  filter: blur(4px);
}


/*Loading Button*/
$btn-duration: .35s;
$btn-duration-total: ($btn-duration * 4) * 2;

.loading-button {
  position: relative;
  border: none;
  cursor: pointer;

  &::before,
  &::after,
  span::before,
  span::after {
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    background: #FFFFFF;
    content:"";
    opacity: 0;
    position: absolute;
    transition: opacity 1s ease;
  }

  &::before,
  &::after {
    height: 2px;
    width: 0%;
  }

  &::before {
    right: 0;
    top: 0;
  }

  &::after {
    bottom: 0;
    left: 0;
  }

  span::before,
  span::after {
    height: 0%;
    width: 2px;
  }

  span::before {
    left: 0;
    top: 0;
  }

  span::after {
    bottom: 0;
    right: 0;
  }

  &.loaded {
    &::before,
    &::after,
    span::before,
    span::after {
      opacity: 1;
    }

    &::before {
      animation-name: btn-border-top;
      animation-duration: $btn-duration-total;
    }

    &::after {
      animation-name: btn-border-bottom;
      animation-delay: $btn-duration * 2;
      animation-duration: $btn-duration-total;
    }

    span::before {
      animation-name: btn-border-span-left;
      animation-delay: $btn-duration;
      animation-duration: $btn-duration-total;
    }

    span::after {
      animation-name: btn-border-span-right;
      animation-delay: $btn-duration * 3;
      animation-duration: $btn-duration-total;
    }
  }
}

@mixin btnAnimations ($name, $anchor, $edge) {
  $anchor-op: 'left';

  @if $anchor == 'left' {
    $anchor-op: 'right';
  }
  @elseif $anchor == 'top' {
    $anchor-op: 'bottom';
  }
  @elseif $anchor == 'bottom' {
    $anchor-op: 'top';
  }

  @keyframes #{$name} {
    0% {
      #{$anchor-op}: auto;
      #{$anchor}: 0;
      #{$edge}: 0%;
    }
    12.5% {
      #{$anchor-op}: auto;
      #{$anchor}: 0;
      #{$edge}: 100%;
    }
    12.6% {
      #{$anchor-op}: 0;
      #{$anchor}: auto;
    }
    50% {
      #{$edge}: 100%;
    }
    62.5% {
      #{$edge}: 0%;
    }
    100% {
      #{$anchor-op}: 0;
      #{$anchor}: auto;
      #{$edge}: 0%;
    }
  }
}

@include btnAnimations(btn-border-top, 'right', 'width');
@include btnAnimations(btn-border-bottom, 'left', 'width');
@include btnAnimations(btn-border-span-left, 'top', 'height');
@include btnAnimations(btn-border-span-right, 'bottom', 'height');


.ReactVirtualized__Table {
  display: inline-block;
  border: 2px solid rgba(0, 0, 0, 0.1);
}

.ReactVirtualized__Table__headerColumn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0;
}

.ReactVirtualized__Table__headerTruncatedText {
  flex: auto;
}

.DragHandle {
  flex: 0 0 16px;
  z-index: 2;
  cursor: col-resize;
  color: #0085ff;
}
.DragHandle:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.DragHandleActive,
.DragHandleActive:hover {
  color: #0b6fcc;
  z-index: 3;
}

.DragHandleIcon {
  flex: 0 0 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ReactVirtualized__Table__headerColumn:first-of-type, .ReactVirtualized__Table__rowColumn:first-of-type {
  margin-left:0px !important;
}

.ReactVirtualized__Table__headerColumn, .ReactVirtualized__Table__rowColumn {
  margin-right:0px !important;
}