@font-face {
  font-family: 'EurestoIcon';
  src:  url('fonts/EurestoIcon.eot?bagj9g');
  src:  url('fonts/EurestoIcon.eot?bagj9g#iefix') format('embedded-opentype'),
    url('fonts/EurestoIcon.ttf?bagj9g') format('truetype'),
    url('fonts/EurestoIcon.woff?bagj9g') format('woff'),
    url('fonts/EurestoIcon.svg?bagj9g#EurestoIcon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="ei-"], [class*=" ei-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'EurestoIcon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ei-module_pedagogie:before {
  content: "\e900";
}
.ei-pedagogie_exercice:before {
  content: "\e901";
}
.ei-commun_chevron_left:before {
  content: "\e902";
}
.ei-commun_chevron_right:before {
  content: "\e903";
}
.ei-gestion_livraison .path1:before {
  content: "\e904";
  color: rgb(168, 160, 125);
}
.ei-gestion_livraison .path2:before {
  content: "\e905";
  margin-left: -1em;
  color: rgb(157, 132, 96);
}
.ei-gestion_livraison .path3:before {
  content: "\e906";
  margin-left: -1em;
  color: rgb(230, 200, 115);
}
.ei-module_mobile .path1:before {
  content: "\e907";
  color: rgb(0, 46, 64);
}
.ei-module_mobile .path2:before {
  content: "\e908";
  margin-left: -1em;
  color: rgb(22, 173, 148);
}
.ei-commun_fermer:before {
  content: "\e909";
}
.ei-commun_chevron_up:before {
  content: "\e90a";
}
.ei-commun_chevron_down:before {
  content: "\e90b";
}
.ei-commun_retour:before {
  content: "\e90c";
}
.ei-commun_cadena_ferme:before {
  content: "\e90d";
}
.ei-commun_pouce:before {
  content: "\e90e";
}
.ei-commun_cadena_ouvert:before {
  content: "\e90f";
}
.ei-commun_connexion:before {
  content: "\e910";
}
.ei-commun_carre_moins:before {
  content: "\e911";
}
.ei-commun_carre_plus:before {
  content: "\e912";
}
.ei-commun_commentaire:before {
  content: "\e913";
}
.ei-common_fleche:before {
  content: "\e914";
}
.ei-commun_copier:before {
  content: "\e915";
}
.ei-commun_etoile_pleine:before {
  content: "\e916";
}
.ei-commun_etoile_vide:before {
  content: "\e917";
}
.ei-commun_deplacer:before {
  content: "\e918";
}
.ei-commun_drapeau_plein:before {
  content: "\e919";
}
.ei-commun_drapeau_vide:before {
  content: "\e91a";
}
.ei-commun_download:before {
  content: "\e91b";
}
.ei-commun_upload:before {
  content: "\e91c";
}
.ei-commun_bloquer:before {
  content: "\e91d";
}
.ei-logo_euresto .path1:before {
  content: "\e91e";
  color: rgb(0, 159, 140);
}
.ei-logo_euresto .path2:before {
  content: "\e91f";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.ei-E_Euresto_new:before {
  content: "\e920";
}
.ei-interface_guestonline .path1:before {
  content: "\e921";
  color: rgb(236, 116, 38);
}
.ei-interface_guestonline .path2:before {
  content: "\e922";
  margin-left: -1em;
  color: rgb(30, 184, 206);
}
.ei-interface_sellsy:before {
  content: "\e923";
}
.ei-restaurant_fait_maison:before {
  content: "\e924";
}
.ei-commun_ajouter:before {
  content: "\e925";
}
.ei-commun_enlever:before {
  content: "\e926";
}
.ei-module_documentation:before {
  content: "\e927";
}
.ei-commun_notification_sms:before {
  content: "\e928";
}
.ei-crm_campagne_sms:before {
  content: "\e929";
}
.ei-commun_position:before {
  content: "\e92a";
}
.ei-module_gestion:before {
  content: "\e92b";
}
.ei-restaurant_notification_refus:before {
  content: "\e92c";
}
.ei-restaurant_notification_annulation:before {
  content: "\e92d";
}
.ei-restaurant_notification_confirmation:before {
  content: "\e92e";
}
.ei-restaurant_notification_demande:before {
  content: "\e92f";
}
.ei-module_fidelite:before {
  content: "\e930";
}
.ei-module_interface:before {
  content: "\e931";
}
.ei-commun_cadre:before {
  content: "\e932";
}
.ei-restaurant_table:before {
  content: "\e933";
}
.ei-commun_paiement:before {
  content: "\e934";
}
.ei-restaurant_menu:before {
  content: "\e935";
}
.ei-commun_notification_email:before {
  content: "\e936";
}
.ei-gestion_vin:before {
  content: "\e937";
}
.ei-gestion_fiche_technique_non_confirmee:before {
  content: "\e938";
}
.ei-crm_facturation:before {
  content: "\e939";
}
.ei-interface_stripe:before {
  content: "\e93a";
  color: #32325d;
}
.ei-crm_client_add:before {
  content: "\e93b";
}
.ei-commun_user_add:before {
  content: "\e93c";
}
.ei-crm_morale:before {
  content: "\e93d";
}
.ei-crm_confidentiel_2:before {
  content: "\e93e";
}
.ei-crm_confidentiel:before {
  content: "\e93f";
}
.ei-crm_physique:before {
  content: "\e940";
}
.ei-commun_etoile:before {
  content: "\e941";
}
.ei-commun_action_mail:before {
  content: "\e942";
}
.ei-commun_civilite:before {
  content: "\e943";
}
.ei-commun_valider:before {
  content: "\e944";
}
.ei-crm_groupe:before {
  content: "\e945";
}
.ei-crm_etablissement:before {
  content: "\e946";
}
.ei-commun_visualiser:before {
  content: "\e947";
}
.ei-commun_dupliquer:before {
  content: "\e948";
}
.ei-commun_supprimer:before {
  content: "\e949";
}
.ei-commun_question:before {
  content: "\e94a";
}
.ei-commun_reouvrir:before {
  content: "\e94b";
}
.ei-reservation_calendrier_date:before {
  content: "\e94c";
}
.ei-module_reservation:before {
  content: "\e94d";
}
.ei-action_sms_envoi:before {
  content: "\e94e";
}
.ei-gestion_dbm:before {
  content: "\e94f";
}
.ei-commun_effacer:before {
  content: "\e950";
}
.ei-commun_rechercher:before {
  content: "\e951";
}
.ei-commun_sortir:before {
  content: "\e952";
}
.ei-crm_public:before {
  content: "\e953";
}
.ei-commun_recharger:before {
  content: "\e954";
}
.ei-commun_horloge:before {
  content: "\e955";
}
.ei-commun_filtrer:before {
  content: "\e956";
}
.ei-sdd_dossier:before {
  content: "\e957";
}
.ei-module_sdd:before {
  content: "\e958";
}
.ei-sdd_dossier_ferme:before {
  content: "\e959";
}
.ei-interface_abill:before {
  content: "\e95a";
}
.ei-commun_modifier:before {
  content: "\e95b";
}
.ei-action_appel_casque:before {
  content: "\e95c";
}
.ei-reservation_calendrier:before {
  content: "\e95d";
}
.ei-reservation_couverts:before {
  content: "\e95e";
}
.ei-commun_envoyer:before {
  content: "\e95f";
}
.ei-logo_BE:before {
  content: "\e960";
}
.ei-logo_E:before {
  content: "\e961";
}
.ei-sdd_classeur:before {
  content: "\e962";
}
.ei-module_crm:before {
  content: "\e963";
}
.ei-module_crm_4:before {
  content: "\e964";
}
.ei-module_crm_3:before {
  content: "\e965";
}
.ei-module_crm_2:before {
  content: "\e966";
}
.ei-module_configuration:before {
  content: "\e967";
}
.ei-gestion_rubrique:before {
  content: "\e968";
}
.ei-gestion_produit:before {
  content: "\e969";
}
.ei-gestion_fiche_technique:before {
  content: "\e96a";
}
.ei-gestion_famille:before {
  content: "\e96b";
}
.ei-gestion_cave:before {
  content: "\e96c";
}
.ei-crm_campagne_email:before {
  content: "\e96d";
}
.ei-commun_user:before {
  content: "\e96e";
}
.ei-commun_user_2:before {
  content: "\e96f";
}
.ei-commun_redmine:before {
  content: "\e970";
}
.ei-commun_option_min:before {
  content: "\e971";
}
.ei-commun_configuration:before {
  content: "\e972";
}
.ei-commun_burger_menu:before {
  content: "\e973";
}
.ei-commun_archiver:before {
  content: "\e974";
}
.ei-action_tache_traitee:before {
  content: "\e975";
}
.ei-action_tache_assignee:before {
  content: "\e976";
}
.ei-action_appel_sortant:before {
  content: "\e977";
}
.ei-action_appel_entrant:before {
  content: "\e978";
}
.ei-action_appel_entrant_2:before {
  content: "\e979";
}
