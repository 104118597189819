.MainBlockProgress {
  transform: skewX(-10deg);
  -ms-transform:skewX(-10deg);
  -webkit-transform:skewX(-10deg);
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.MainBlockProgress2 {
  transform: skewX(-10deg);
  -ms-transform:skewX(-10deg);
  -webkit-transform:skewX(-10deg);
  border-top-right-radius: 19px;
  border-bottom-right-radius: 19px;
  -webkit-transition-delay: 500ms;
  -moz-transition-delay: 500ms;
  -o-transition-delay: 500ms;
  transition-delay: 500ms;
  transition: width 500ms;
}