/* PAGE */
.navigationPage:hover {
  color: $secondaryColor !important;
}

.react-sanfona-item-expanded>h3{
  color: #c7cf00;
  text-shadow: #000 0 3px 9px;
}

.dropZone{
  border: 2px dashed $primaryColor;
}


// MENU
.react-sanfona{
  color: #cccccc;
}
.react-sanfona-item-body-wrapper{
  //background-color: #ffffff;
}

.react-sanfona-item-body-wrapper a.menu-item {
  border-right: 2px solid #eeeeee;
  border-bottom: 2px solid #eeeeee;
  border-top: 2px solid #eeeeee;
  color: $primaryColor !important;
  background-color: white;
  border-left: 3px solid $secondaryColor;
}


.react-sanfona-item-body-wrapper a.menu-item.page-layout__nav-item--active {
  border-right: 2px solid $secondaryColor;
  border-bottom: 2px solid $secondaryColor;
  border-top: 2px solid $secondaryColor;
}

.menu-title-up:after{
  border-top-color: #dddddd;
}

.menu-item:hover{
  border-left: 5px solid $secondaryColor !important;
  color: $secondaryColor !important;
}

.MainMenuItem a.menu-item
{
  //color: $textColor !important;
  border-left: 3px solid transparent;
  padding-left: 3px;
}

.react-sanfona-item-body-wrapper a.menu-item:hover

, .MainMenuItem a.menu-item:hover
{
  border-left: 3px solid $secondaryColor !important;
  color: $secondaryColor !important;
  padding-left: 3px;
}


.bmBurgerButton {
  background: $primaryColor
}
.bmBurgerBars{
  background: $primaryColor
}
.bmMenu{
  background: $primaryColor;
}
.bmMorphShape{
  fill: $primaryColor
}
.bmItemList{
  color: $primaryColor
}
.bm-menu{
  background: $primaryColor !important;
}

.fieldset-crit-block > legend::after,
.fieldset-crit-block > legend::before{
  content: '';
  position: absolute;
  border-style: solid;
  border-color: transparent;
  bottom: -10px;
}

.fieldset-crit-block{
  border: 0.5px solid #e9ecef;
  padding: 10px;
}

.primaryColor{
  color:$primaryColor !important;
}
.secondaryColor{
  color: $secondaryColor !important;
}

.ficheUser div button.tabUser{
  background-color: $primaryColor !important;
  color: $textColor;
  text-shadow: 0 1px rgba(0,0,0,.8);
  background-image:  linear-gradient(rgba(255,255,255,.3), rgba(255,255,255,0));
}


//Define Class to reuse everyWhere

.EurestoPrimaryOpacity {
  background-color: $primaryColorOpacity !important
}
.EurestoPrimaryOpacity--text {
  color: $primaryColorOpacity !important
}
.EurestoPrimary--fill:before {
  color: $primaryColor !important
}
.EurestoSecondary--fill:before {
  color: $secondaryColor !important
}
.EurestoText--fill:before {
  color: $textColor !important
}
.EurestoError--fill:before {
  color: #F05D5E !important
}
.EurestoPrimary--text {
  color: $primaryColor !important
}
.EurestoPrimary {
  background-color: $primaryColor !important
}

.hoveredItem {
  background-color: $primaryColor !important;
  color: $textColor !important;
}

.selectedItem {
  background-color: $secondaryColor !important;
  color: $textColor !important;
}

.EurestoSecondary--text {
  color: $secondaryColor !important
}
.EurestoSecondary {
  background-color: $secondaryColor !important
}

.EurestoText--text {
  color: $textColor !important
}
.EurestoText {
  background-color: $textColor !important
}

.disabled {
  color: #9C9C9C !important;
}

.disabled-bg {
  background-color: #9C9C9C
}



.MenuG {
  background-image:  linear-gradient(rgba(255,255,255,.1), rgba(255,255,255,0));
  -moz-box-shadow: 0 2px 0 rgba(0,0,0,.3);
  -webkit-box-shadow: 0 2px 0 rgba(0,0,0,.3);
  position: relative;
  max-width: none;
  width: calc(100% + 20px);
  text-align: center;
  box-shadow: 0 1px 0 $secondaryColor;
}


.MenuItemList.active{
  background-color: $secondaryColor !important;
  width: calc(100% - 3px) !important;
}

.MenuItemList:hover{
 border-left: 3px solid $secondaryColor !important;
}

.ConfigurationMenu {
  background-color: $textColor !important;
  color: $secondaryColor !important;
  padding: 2.5px 0 2.5px 2.5px;
  border-top: 1px solid lightgray !important;
  border-bottom: 1px solid lightgray !important;
}

.ConfigurationMenu.active, .ConfigurationMenu:hover{
  // border: 3px solid $secondaryColor !important;
  position: relative;
  display: block;
  background: #04a4a9;
  padding: 1em;
  overflow: hidden;
}

.ConfigurationMenu.active:before, .ConfigurationMenu:hover:before{
  content: '';
  position: absolute;
  width: 10px;
  height: 10px;
  background: $secondaryColor;
  left: -8px;
  top: 50%;
  transform: translate(0, -50%) rotate(45deg);
}

.MainMenuItem a.page-layout__nav-item--active.menu-item, .react-sanfona-item-body-wrapper-nrm a.page-layout__nav-item--active.menu-item * {
  color: $primaryColor !important;
  background-color: $secondaryColor;
  font-weight: 600 !important;
}
//SCROLLBAR


.rcs-inner-handle {
  background-color: transparent !important;
}


.ReactTable.-striped .rt-tr.-odd {
  background-color: #FFFFFF;
}

.rt-resizable-header {
  line-height: inherit !important;
}

.ReactTable .rt-th, .ReactTable .rt-td {
  padding: 0px !important;
  text-align: center;
  vertical-align: middle !important;
  border-bottom: none !important;
}

.rt-noData {
  display: none !important;
}

.ReactTable .rt-tbody {
  display: block !important;
  overflow-x: hidden;
}


.ReactTable .rt-thead {
  z-index: 1;
  box-shadow: 0 2px 5px $primaryColor !important;
  height: 28px;
  line-height: 28px;
  color: $primaryColor !important;
  overflow: visible !important;
}

.ReactTable {
  border-radius: 5px;
  border-bottom: none !important;
}
